import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo.js';
import Matomo from '../Matomo.js';
import { FooterContact } from './FooterContact.js';
import GPTModal from './modals/GPTmodal.js';
import AI_Assistant_Icon from '../general_questions/images/J.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ImageButton from './components/ImageButton.js';
import { sendToInpersons } from '../../Helpers.js';
import ImageButtonWithDropdown from './components/ImageButtonWithDropdown.js';

//required props: 

export class ConditionChooser extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            nextpage : props.nextpage || props.next,
            default_nextpage : props.default_next,
            mounted : false,  
            startIndex: 0,
            visibleItemsCount: null, // Number of items to display
            itemWidth: 245, // Width of each item (update as needed)
            scrollOffset: 0, // New state variable for scroll offset
            condition_levels : props.condition_levels,
            err: null,
        }
    }

    componentDidMount = () => {
        /*-Matomo Analytics-*/
        const title = this.props.page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        if(!this.props.showpage){
            const event = new Event("emptyevent");
            this.continue(this.state.default_nextpage, true)(event);
        }
        this.scrollToTop();
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
        this.setState({ mounted: true });
        //Scroll to top of page
        if(this.props.values_business.businessid !== 'hellerbrothers'){
            this.getTop().scrollIntoView();
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    getTop = () => {
        return document.getElementById('surveywrapper')
    };

    continue = (next, skip = false) => e =>{
        e.preventDefault();
        this.props.nextPage(next, skip);
    };

    handleWindowResize = () => {
        // Calculate and update the visibleItemsCount based on the window width
        const button_width_px = 245;
        const margin_left_px = 100;
        const veiwport_buttons = Math.floor((window.innerWidth - margin_left_px) / button_width_px);
        const max_buttons = 4;
        const visibleItemsCount = Math.min(veiwport_buttons, max_buttons);
        
        this.setState({ visibleItemsCount });
    }

    scrollLeft = () => {
        this.setState(prevState => {
            const newStartIndex = Math.max(prevState.startIndex - prevState.visibleItemsCount, 0);
            console.log("scrolling left " + newStartIndex * prevState.itemWidth + "px")
            return {
                startIndex: newStartIndex,
                scrollOffset: newStartIndex * prevState.itemWidth
            };
        });
    }
    
    scrollRight = () => {
        this.setState(prevState => {
            const newStartIndex = Math.min(prevState.startIndex + prevState.visibleItemsCount, prevState.condition_levels.length - prevState.visibleItemsCount);
            console.log("scrolling right " + newStartIndex * prevState.itemWidth + "px")
            const newScrollOffset = newStartIndex * prevState.itemWidth;
            return {
                startIndex: prevState.scrollOffset !== newScrollOffset ? newStartIndex : prevState.startIndex,
                scrollOffset: newScrollOffset
            };
        });
    }

    scrollToTop = () => {
        return document.getElementById('surveywrapper')
    }

    showModal = () => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true });
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    getButtonActivity = val =>{
        var saved = this.props.values_customer[this.props.state_var];
        console.log(saved)
        if (saved===val) {
            return 'imgbtn active';
        }
        return 'imgbtn';
    }

    handleClick = (choice) => e => {
        try {
            console.log(choice)
            const { handleChangeObjectNext, handleChangeEstimateReason, estimate_reason} = this.props;
            const { default_state_var, page_title, values_business, values_customer, condition_levels} = this.props;
            const { default_nextpage } = this.state;
            //const choice = condition_levels.find(choice => choice.value === choice_value) || c;
            const next_page = (choice.nextpage) ? choice.nextpage : default_nextpage;
            const choice_value = (choice.numerical_value !== null && choice.numerical_value !== undefined) ? choice.numerical_value : choice.value;
            const is_inperson = sendToInpersons(choice_value, default_state_var, values_customer, values_business.inpersons, page_title);
            const state_var = (choice.state_var) ? choice.state_var : default_state_var;
            if (is_inperson){
                handleChangeEstimateReason(estimate_reason, default_state_var, choice.value)
            } else {
                console.log(state_var)
                console.log(choice.value)
                console.log(next_page)
                handleChangeObjectNext(next_page, state_var, choice.value)
            }
        } catch (error) {
            console.log("error in ConditionChooser.handleClick: " + error)
            this.setState({err: error});
        }
    }

    renderButtons = () => {
        const { handleChangeObjectNext, handleChangeEstimateReason, condition_levels, state_var } = this.props;
        return condition_levels.map((level)  => (
            <ImageButtonWithDropdown
                choice={level}
                state_var={state_var}
                handleClick={this.handleClick}
                values_customer={this.props.values_customer}
                />
        ));
    }

    render() { 
        const { handleChangeObjectNext, handleChangeEstimateReason ,values_business, values_customer, is_mobile_display } = this.props;
        const {aboutthis, condition_levels, state_var, header_question, gpt_prompts} = this.props;
        const { visibleItemsCount, itemWidth, scrollOffset } = this.state;
        
        const render_right_nav = condition_levels.length > visibleItemsCount && scrollOffset < (condition_levels.length - visibleItemsCount) * itemWidth;
        const render_left_nav = condition_levels.length > visibleItemsCount && scrollOffset > 0;
        const button_row_max_width = (visibleItemsCount + .75) * itemWidth;
        const button_row_min_width = (visibleItemsCount) * itemWidth;

        if (this.state.err) throw this.state.err;
        return (
            <div className='surveywrapper' id='surveywrapper'>
                {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={gpt_prompts} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry={values_business.industry}/> : null}
                <div className='multiplechoice-transitionbuffer'>
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div className='multiplechoice'>
                            
                            <div className='textwrapper'>
                                <h2 className='large'> {header_question} </h2>
                                <h4 className='small'>Choose one.</h4>
                                <p className='clickable display-flex-row' onClick={this.showModal}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;Ask anything about this</p>
                            </div>
                            {!is_mobile_display && <div className='row' style={{justifyContent:'center', maxWidth : button_row_max_width, minWidth: button_row_min_width,alignSelf: 'center'}}>
                                <div className='navicon-group'>
                                    <FontAwesomeIcon 
                                        className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronLeft}
                                        //size='3x'
                                        onClick={this.scrollLeft}
                                    />
                                    <FontAwesomeIcon 
                                        className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronLeft}
                                        //size='3x'
                                        onClick={this.scrollLeft}
                                    />

                                </div>
                                <div className={(render_left_nav || render_right_nav) ? "choices-container scrolling" : "choices-container"} style={{width: button_row_min_width}}>
                                    <div className={(render_left_nav || render_right_nav) ? "choices-wrapper" : "choices-wrapper no-margin"} style={{ transform: `translateX(-${scrollOffset}px)`, transition: 'transform .5s ease-in-out' }}>
                                        {this.renderButtons()}  
                                    </div>
                                </div>
                                <div className='navicon-group'>
                                    <FontAwesomeIcon 
                                        className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronRight}
                                        //size='3x'
                                        onClick={this.scrollRight}
                                    />
                                    <FontAwesomeIcon 
                                        className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronRight}
                                        //size='3x'
                                        onClick={this.scrollRight}
                                    />
                                </div>
                            </div>}
                            {/* Mobile button rendering  */}
                            {is_mobile_display &&
                                <div className='row'>
                                    {this.renderButtons()}
                                </div>
                            }
                            {!is_mobile_display && <div className='textwrapper'>
                                <h2 className='large invis'>.</h2>
                                <h4 className='small invis'>.</h4>
                                <p className='small btntitle invis'>.</p>
                            </div>}
                            <div className="fillremainderflex"/>
                            {is_mobile_display && <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>}
                        </div>
                    </CSSTransition>
                    {!is_mobile_display && <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>}
                </div>
            </div>
        )
    }
}

export default ConditionChooser;