import React, {Component} from 'react';
import {AboutModal} from '../questions/Modals/AboutModal.js';
import { CSSTransition } from 'react-transition-group';
import { FooterLogo } from './FooterLogo.js';
import Matomo from '../Matomo.js';
import { PreviewModal } from '../questions/Modals/PreviewModal.js';
import GPTModal from '../general_questions/modals/GPTmodal'
import AI_Assistant_Icon from '../general_questions/images/J.png'
import { FooterContact } from './FooterContact.js';
import { findObjectByProperty, sendToInpersons } from '../../Helpers.js';
import MultipleChoiceGPTButton from './components/MultipleChoiceGPTButton.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faImage} from '@fortawesome/free-solid-svg-icons';
import ButtonWithDescription from './components/ButtonWithDescription.js';
import ButtonWithIcon from './components/ButtonWithIcon.js';
import ButtonWithImageIcon from './components/ButtonWithImageIcon.js';

//REQUIRED PROPS: page_title, showpage, next, nextPage, values_customer, values_business, default_state_var, header_question, choices, HandleChangeObjectNext


export class MultipleChoiceText extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showmodal : false,
            default_nextpage : props.default_next,
            mounted : false,
            startIndex: 0,
            visibleItemsCount: null, // Number of items to display
            itemWidth: 245, // Width of each item (update as needed)
            scrollOffset: 0, // New state variable for scroll offset
            all_choices : props.choices,
            err: null,
        }
    }

    componentDidMount = () => {
        const {default_nextpage} = this.state;
        const {page_title, showpage, selection_required, values_business} = this.props;

        /*-Matomo Analytics-*/
        const title = page_title;
        Matomo(title);
        /*----End Matomo----*/
        
        //Skip to next page if necessary
        console.log('showpage: ' + showpage)
        if(!showpage){
            if (selection_required){ //select the first/only choice and skip page
                this.handleClick(this.state.all_choices[0], true)();
            } else {
                console.log('skipping page: ' + title)
                this.props.nextPage(default_nextpage, true);
            }
        }
        if(this.props.is_mobile_display === null || this.props.is_mobile_display === undefined){
            console.error("is_mobile_display is not provided in MultipleChoiceText for " + page_title)
        }
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
        this.setState({ mounted: true });
        if (values_business.businessid !== 'cnysealing' && values_business.businessid !== 'hellerbrothers') { //cny has a disclaimer above widget, do not auto scroll past it on first page
            this.getTop().scrollIntoView();
        }
    }

    componentWillUnmount() {
        // Remove the event listener when the component is unmounted
        window.removeEventListener('resize', this.handleWindowResize);
    }

    getTop = () => {
        return document.getElementById('top');
    };

    showModal = (prompts, intro_query=null) => {
        document.body.style.overflow = 'hidden';
        this.setState({ showmodal: true, modal_gpt_prompts: prompts, modal_intro_query: intro_query});
    };
    
    hideModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showmodal: false });
    };

    showPreviewModal = () => e =>{
        document.body.style.overflow = 'hidden';
        this.setState({ showpreviewmodal: e.currentTarget.id });
    };

    hidePreviewModal = () => {
        document.body.style.overflow = 'auto';
        this.setState({ showpreviewmodal: '' });
    };

    handleWindowResize = () => {
        // Calculate and update the visibleItemsCount based on the window width
        const button_width_px = this.state.itemWidth;
        const margin_left_px = 100;
        const veiwport_buttons = Math.floor((window.innerWidth - margin_left_px) / button_width_px);
        const max_buttons = 4;
        const visibleItemsCount = Math.min(veiwport_buttons, max_buttons);
        
        this.setState({ visibleItemsCount });
    }

    scrollLeft = () => {
        this.setState(prevState => {
            const newStartIndex = Math.max(prevState.startIndex - prevState.visibleItemsCount, 0);
            console.log("scrolling left " + newStartIndex * prevState.itemWidth + "px")
            return {
                startIndex: newStartIndex,
                scrollOffset: newStartIndex * prevState.itemWidth
            };
        });
    }
    
    scrollRight = () => {
        this.setState(prevState => {
            const newStartIndex = Math.min(prevState.startIndex + prevState.visibleItemsCount, prevState.all_choices.length - prevState.visibleItemsCount);
            console.log("scrolling right " + newStartIndex * prevState.itemWidth + "px")
            const newScrollOffset = newStartIndex * prevState.itemWidth;
            return {
                startIndex: prevState.scrollOffset !== newScrollOffset ? newStartIndex : prevState.startIndex,
                scrollOffset: newScrollOffset
            };
        });
    }

    renderButtons = () => {
        const { all_choices, startIndex, visibleItemsCount } = this.state;
        const { handleChangeObjectNext, default_state_var } = this.props;

        if(all_choices && all_choices[0] && all_choices[0].icon){
            return all_choices
            .map((choice) => (
                <ButtonWithIcon
                    key={choice.title}
                    choice={choice}
                    handleClick={this.handleClick(choice)}
                    values_customer={this.props.values_customer}
                    state_var={choice.state_var || default_state_var}
                />
            ));
        } else if (all_choices && all_choices[0] && all_choices[0].image_icon){
            return all_choices
            .map((choice) => (
                <ButtonWithImageIcon
                    key={choice.title}
                    choice={choice}
                    handleClick={this.handleClick(choice)}
                    values_customer={this.props.values_customer}
                    state_var={choice.state_var || default_state_var}
                />
            ));
        } else {
            return all_choices
                //.slice(startIndex, startIndex + visibleItemsCount)
                .map((choice) => (
                    <div>
                    <div className='column'>
                        <ButtonWithDescription
                            key={choice.title}
                            choice={choice}
                            handleClick={this.handleClick(choice)}
                            values_customer={this.props.values_customer}
                            state_var={choice.state_var || default_state_var}
                            is_mobile_display={this.props.is_mobile_display}
                        />
                    </div>
                    {(choice.preview) ? <p className='m-small clickable less-margin' id={choice.title} onClick={this.showPreviewModal()}><FontAwesomeIcon icon={faImage}/> See example</p> : <p></p>}
                    </div>
                ));
        }
    }


    getPreviewModal = (choice) => {
        const {is_mobile_display} = this.props;
        if(!choice || !choice.preview){
            return;
        }
        const title = choice.title;
        const url = choice.preview;
        const content = {title: title, src :url};
        const show = (this.state.showpreviewmodal === title);
        return(
            <PreviewModal
                show={show} 
                handleClose={() => this.hidePreviewModal()} 
                info={content}
                is_mobile_display={is_mobile_display}>
            </PreviewModal>
        )
    }

    getAboutModal = (aboutthis) => {
        if(!aboutthis){
            return;
        }
        return <AboutModal show={this.state.showmodal} handleClose={this.hideModal} info={aboutthis}/>
    }

    handleClick = (choice, skip=false) => e => {
        try {
            console.log(choice)
            const { handleChangeObjectNext, handleChangeEstimateReason, estimate_reason} = this.props;
            const { default_state_var, page_title, values_business, values_customer} = this.props;
            const { default_nextpage } = this.state;
            const next_page = (choice.nextpage) ? choice.nextpage : default_nextpage;
            const is_inperson = sendToInpersons(choice.value, default_state_var, values_customer, values_business.inpersons, page_title);
            const state_var = (choice.state_var) ? choice.state_var : default_state_var;
            if (is_inperson){
                handleChangeEstimateReason(estimate_reason, state_var, choice.value)
            } else {
                console.log(state_var)
                console.log(choice.value)
                handleChangeObjectNext(next_page, state_var, choice.value, skip)
            }
        } catch (error) {
            console.log("error in MultipleChoiceText.handleClick")
            this.setState({err: error});
        }
    }

    render() { 
        const { handleChangeObjectNext} = this.props;
        const {aboutthis, hide_gpt, header_question, values_business, values_customer, gpt_prompts, is_mobile_display, extra_classname} = this.props;
        const { modal_intro_query, modal_gpt_prompts, visibleItemsCount, scrollOffset, itemWidth, all_choices } = this.state;
        const render_right_nav = all_choices.length > visibleItemsCount && scrollOffset < (all_choices.length - visibleItemsCount) * itemWidth;
        const render_left_nav = all_choices.length > visibleItemsCount && scrollOffset > 0;
        const button_row_max_width = (visibleItemsCount + .75) * itemWidth;
        const button_row_min_width = (visibleItemsCount) * itemWidth;
        
        if (this.state.err) throw this.state.err;
        return (
            <div className='surveywrapper' id='top'>
                <div className={'multiplechoice-transitionbuffer ' + extra_classname}>
                {this.state.showpreviewmodal ? this.getPreviewModal(findObjectByProperty(all_choices, 'title', this.state.showpreviewmodal)) : null}
                {this.state.showmodal ? <GPTModal handleClose={this.hideModal} prompts={modal_gpt_prompts} intro_query={modal_intro_query} displayclass={"modal display-block"} company_name={this.props.values_business.businessname} industry={values_business.industry}/> : null}
                    <CSSTransition
                            in={this.state.mounted}
                            timeout={1000}
                            classNames="fade"
                            unmountOnExit 
                            appear
                    >
                        <div className='multiplechoice'>
                            
                            <div className='textwrapper'>
                                <h2 className='large'> {header_question} </h2>
                                <h4 className='small'>Choose one.</h4>
                                {!hide_gpt && <p className='clickable display-flex-row' onClick={() => this.showModal(gpt_prompts)}><img className='assistant-icon' src={AI_Assistant_Icon} alt='chat icon'/>&nbsp;Ask anything about this</p>}
                            </div>
                            {!is_mobile_display && <div className='row' style={{justifyContent:'center', maxWidth : button_row_max_width, minWidth: button_row_min_width,alignSelf: 'center'}}>
                                <div className='navicon-group'>
                                    <FontAwesomeIcon 
                                        className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronLeft}
                                        //size='3x'
                                        onClick={this.scrollLeft}
                                    />
                                    <FontAwesomeIcon 
                                        className={(render_left_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronLeft}
                                        //size='3x'
                                        onClick={this.scrollLeft}
                                    />
                                </div>
                                <div className={(render_left_nav || render_right_nav) ? "choices-container scrolling" : "choices-container"} style={{width: button_row_min_width}}>
                                    <div className={(render_left_nav || render_right_nav) ? "choices-wrapper" : "choices-wrapper no-margin"} style={{ transform: `translateX(-${scrollOffset}px)`, transition: 'transform .5s ease-in-out' }}>
                                        {this.renderButtons()}  
                                    </div>
                                </div>
                                <div className='navicon-group'>
                                    <FontAwesomeIcon 
                                        className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronRight}
                                        //size='3x'
                                        onClick={this.scrollRight}
                                    />
                                    <FontAwesomeIcon 
                                        className={(render_right_nav) ? 'navicon' : 'navicon invis'}
                                        icon={faChevronRight}
                                        //size='3x'
                                        onClick={this.scrollRight}
                                    />
                                </div>
                            </div>}
                            {/* Mobile button rendering  */}
                            {is_mobile_display &&
                                <div className='row'>
                                    {this.renderButtons()}
                                </div>
                            }
                            {!is_mobile_display && <div className='textwrapper'>
                                <h2 className='large invis'>.</h2>
                                <h4 className='small invis'>.</h4>
                                <p className='small btntitle invis'>.</p>
                            </div>}
                            <div className="fillremainderflex"/>
                            {is_mobile_display && <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>}
                        </div>
                    </CSSTransition>
                    {!is_mobile_display && <FooterContact values_business={values_business} phone={values_customer.branch.phone}/>}
                </div>
            </div>
        )
    }
}

export default MultipleChoiceText;